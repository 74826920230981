<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/category/lists">Quản lý Danh mục tin tức</a>
      </li>

      <li class="breadcrumb-item">Cập nhật Danh mục tin tức</li>
    </ol>

    <div v-if="is_load">
      <SkeletonTheme>
        <Skeleton height="100px" v-for="k in 10" :key="k"></Skeleton>
      </SkeletonTheme>
    </div>

    <form method="post" @submit.prevent="mySubmit" id="sendform" v-else>
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name"
                            type="text"
                            v-model="from.name"
                          />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="parentId"> Danh mục cha</label>
                          <select
                            class="form-control"
                            name="parentId"
                            v-model="from.parentId"
                          >
                            <option value="" selected>Tùy chọn</option>
                            <template v-for="elm in list_cate">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                                v-if="elm.parentId == null"
                              >
                                {{ elm.name }}
                              </option>
                            </template>
                          </select>

                          <p class="text-danger">{{ error.parentId }}</p>
                        </div>
                      </div>

                      <div class="col-sm-10" style="display: none">
                        <div class="form-group">
                          <label for="cateId"> Danh mục con</label>
                          <select
                            class="form-control"
                            name="cateId"
                            v-model="from.cateId"
                          >
                            <option value="" selected>Tùy chọn</option>
                            <template v-for="elm in list_cate">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                                v-if="elm.parentId != null"
                              >
                                {{ elm.name }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="status">Trạng thái </label>
                          <select
                            class="form-control col-sm-3"
                            name="status"
                            v-model="from.status"
                          >
                            <option value="1" selected>Hiện</option>
                            <option value="2">Ẩn</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="isMenu">MENU </label
                          ><select
                            name="isMenu"
                            class="form-control col-sm-3"
                            v-model="from.isMenu"
                          >
                            <option value="1" selected>Hiện</option>
                            <option value="2">Ẩn</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="orderBy">Sắp sếp</label>
                          <input
                            class="form-control"
                            name="orderBy"
                            type="number"
                            v-model="from.orderBy"
                          />
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description"> Mô tả </label>

                          <textarea
                            name="description"
                            class="form-control"
                            v-model="from.description"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content"> Nội dung </label>

                          
                          <ckeditor
                            :config="editorConfig"
                            v-model="content"
                             :editor-url="editorUrl"
                              @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Page </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Name <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name_EN"
                            type="text"
                            v-model="from.name_EN"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description_EN">Description </label>

                          <textarea
                            name="description_EN"
                            class="form-control"
                            v-model="from.description_EN"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content_EN"> Content </label>
                           <ckeditor
                            :config="editorConfig_EN"
                            v-model="content_EN"
                             :editor-url="editorUrl"
                              @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-save"></i> Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

export default {
  data() {
    return {
      is_load: false,
      from: {},
      id: this.$route.params.id,
      content: "",
      content_EN: "",
      error: {
        name: "",
        name_EN: "",
      },
       editorConfig: window.editorConfig,
      editorConfig_EN: window.editorConfig_EN,
      list_cate: null,
      editorUrl: "https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js",
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
  },
  mounted() {
    this.is_load = true;
    axios
      .get(window.my_api + "api/newscate/get-newscates")
      .then((res) => {
        if (res.status == 200) {
          this.list_cate = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        window.my_api + "api/newscate/get-newscate-by-id?newscateId=" + this.id,
             {
                    headers: window.my_header
             }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
          this.content = res.data.data.content;
          this.content_EN = res.data.data.content_EN;
          this.is_load = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
      //window.CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
  },
  methods: {
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( "video", "/layout/ckeditor/video/plugin.js" );
     
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên tiếng anh";
          } else {
            this.error.name_EN = "";
          }
        }
      }

      if (this.error.name == "" && this.error.name_EN == "") {
        formData.append("content", this.content);
        formData.append("content_EN", this.content_EN);
        this.is_load = true;
        axios
          .post(
            window.my_api + "api/newscate/create-or-update-newscate",
            formData,
            {
                    headers: window.my_header
             }
          )
          .then((res) => {
            if (res.status == 200) {
             alert("Sửa thành công.");
              this.is_load = false;
             // this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
            } else {
              alert("Cập nhật không thành công, vui lòng liên hệ admin");
            }
          });
      } else {
       
console.log(this.alertError());

alert("Vui lòng nhập đầy đủ");

      }
    },
  },
};
</script>